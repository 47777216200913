import React from 'react';
import RetroGrid from './components/magicui/retro-grid';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-white text-black">
      <nav className="fixed top-0 left-0 right-0 z-20 bg-white text-black shadow-md">
        <div className="container mx-auto px-4 py-3 flex items-center">
          <h1 className="text-1xl font-bold">THE PARKOUR COMPANY</h1>
        </div>
      </nav>

      <main className="flex-grow relative flex flex-col items-center justify-center pt-16">
        <RetroGrid className="z-0" />
        <div className="z-10 text-center">
          <p className="text-6xl font-semibold text-black mb-2 max-w-3xl mx-auto">
            Data for Autonomous Humanoid Robots
          </p>
          <p className="text-xl text-gray-700 mb-8 max-w-2xl mx-auto">
            Diverse whole-body motion control data of parkour movements from the
            world's best athletes.
          </p>
        </div>
      </main>
    </div>
  );
}

export default App;
